import React, { useState, useEffect } from "react";

// Components import
import SliderMenu from "../components/SliderMenu";

// Image imports
import arrow from "../assets/images/icons/arrow.svg";
import curvedArrow from "../assets/images/icons/curved-arrow.svg";

// Routes API
// import { Route, Router, Routes, useLocation } from "react-router-dom";

// API
import axios from "axios";
import { API_URL } from "../config/api";

const Menu = () => {
  const [pancake, setPancake]: [any, any] = useState();

  const fetchPancakeData = async () => {
    const { data } = await axios.get(API_URL);
    setPancake(data);
  };

  useEffect(() => {
    fetchPancakeData();
  }, []);

  return (
    <>
      <section className="menu">
        <div className="menu-selection">
          <h1>Jelovnik</h1>
          {/* <ul>
            <li>
              <a className="active-link" href="/slatke">
                Slatke
              </a>
            </li>
            <li>
              <a href="/slane">Slane</a>
            </li>
            <li>
              <a href="/zdrave">Zdrave</a>
            </li>
          </ul> */}
        </div>
        <div className="menu-listed">
          <div className="menu-first listed">
            {pancake &&
              pancake.slice(0, 3).map((pancake: any, index: number) => {
                return (
                  <div key={index} className="pancake-card">
                    <div className="pancake-card-image">
                      <img
                        className="pancakeImg"
                        src={pancake.Slika}
                        alt="Slike palačinke"
                      />
                    </div>
                    <div className="pancake-card-info">
                      <h1>{pancake.Ime}</h1>
                      <h2>{pancake.Sastojci}</h2>
                      <h5>{pancake.Cijena} </h5>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="menu-second listed">
            {pancake?.slice(3, 6).map((pancake: any, index: number) => {
              const Ime: string = pancake.Ime;
              const Sastojci: string = pancake.Sastojci;
              const Cijena: number = pancake.Cijena;
              const Slika: any = pancake.Slika;
              return (
                <div key={index} className="pancake-card">
                  <div className="pancake-card-image">
                    <img
                      className="pancakeImg"
                      src={Slika}
                      alt="Slike palačinke"
                    />
                  </div>
                  <div className="pancake-card-info">
                    <h1>{Ime}</h1>
                    <h2>{Sastojci}</h2>
                    <h5>{Cijena}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <SliderMenu pancake={pancake} />
        <div className="button__wrapper menu-button">
          <a href="/pancake" className="order">
            <p>Vidi sve</p>
          </a>
          <img src={curvedArrow} alt="Arrow" />
          <p>Najbolje palačinke</p>
        </div>
      </section>
    </>
  );
};

export default Menu;
