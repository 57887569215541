import emailjs from "emailjs-com";

// Image imports
import panBg from "../assets/images/pan-bg.jpg";

const Contact = () => {
  function sentEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_04ibbl6",
        "template_e39956k",
        e.target,
        "-BK83FuFmcR5gx7v_"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <>
      <section className="contact">
        <h1>Pošaljite nam poruku</h1>
        <div className="contact-wrapper">
          <div className="contact">
            <div className="contact-info">
              <h4>Kontakt</h4>
              <form onSubmit={sentEmail}>
                <label htmlFor="name">Ime i Prezime*</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Ime i Prezime"
                  defaultValue={""}
                />
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  defaultValue={""}
                />
                <label htmlFor="message">Poruka*</label>
                <textarea
                  name="message"
                  placeholder="Poruka"
                  id="textarea"
                  cols={20}
                  rows={10}
                  defaultValue={""}
                />
                <button
                  className="submit-btn contact-button"
                  type="submit"
                  name="submit"
                >
                  Pošalji Poruku
                </button>
              </form>
            </div>
          </div>
          <div className="image-container">
            <img src={panBg} alt="Pancake and background" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
