export const API_URL =
  "https://script.google.com/macros/s/AKfycbwj65Uo4St3nwLtHdzyP9KHHUhu_TubsboGHwOuDUlPvcKqlrOwp6q4Yxl12TS0MWZu/exec?action=getPancakes";
export const SALTY_PANCAKE_URL =
  "https://script.google.com/macros/s/AKfycbxR9A9-AzjOCqvHR6xPzK73fHoQRR6l1pZMQedy_Fo8QW7CeuO2g_aRy0Zv2RE4mqzI/exec?action=getPancakes";
export const FRIED_PANCAKE_URL =
  "https://script.google.com/macros/s/AKfycbzt9XNcORTDqk4ma_iaTMA0Iff4vMZRYLU9zwx45K-JYKJz04gFYULWdCYTZ1agdErG/exec?action=getPancakes";
export const HOT_DRINKS_URL =
  "https://script.google.com/macros/s/AKfycbzRwgXbyRkxJtpYQhR-YWulP631tt5z1cgrJbZzWPPz80e9JpJY7PS2-KkPKj_PDh6T/exec?action=getDrinks";
export const ALCOHOLIC_DRINKS_URL =
  "https://script.google.com/macros/s/AKfycbz8Zaip3mUww6ZtoA4PdsFGYQqmLx0lZGOsbldvJ1jhvUQFL-mOlqaD8Xjh_2DUlfvX7Q/exec?action=getDrinks";
export const NON_ALCOHOLIC_DRINKS_URL =
  "https://script.google.com/macros/s/AKfycbyNDMwp57zV6Bd6Xt-YmF4FINedIGnaL8gY55FJ-tZZ5fYdHDyvfS7FQpxhyeeiqHcgbg/exec?action=getDrinks";
