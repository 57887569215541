import React, { useState } from "react";

/* Import Images */
import logo from "../assets/images/logo.png";
import instagram from "../assets/images/icons/instagram.svg";
import github from "../assets/images/icons/github.svg";
import linkedin from "../assets/images/icons/linkedin.svg";
import background from "../assets/images/background.jpg";
import arrow from "../assets/images/icons/arrow.svg";
import main from "../assets/images/main.png";
import curvedArrow from "../assets/images/icons/curved-arrow.svg";
import banana from "../assets/images/banana.png";
import blueberrie from "../assets/images/blueberrie.png";
import blueberries from "../assets/images/blueberries.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };

  // If i click on link, the menu should be closed
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header>
        <nav>
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <div className="links">
            <ul>
              <li>
                <a href="/" className="main-links active">
                  Naslovna
                </a>
              </li>
              <li>
                <a className="main-links" href="/pancake">
                  Jelovnik
                </a>
              </li>
              <li>
                <a className="main-links" href="#about">
                  O nama
                </a>
              </li>
              <li>
                <a className="find-btn" href="#map">
                  Pronađi nas
                </a>
              </li>
            </ul>
          </div>
          <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
            <ul>
              <li>
                <a href="/">Naslovna</a>
              </li>
              <li>
                <a href="/pancake">Jelovnik</a>
              </li>
              <li>
                <a onClick={handleClick} href="#about">
                  O nama
                </a>
              </li>
              <li>
                <a
                  onClick={handleClick}
                  className="find-btn-mobile"
                  href="#map"
                >
                  Pronađi nas
                </a>
              </li>
            </ul>
          </div>
          <div className="socials">
            <a href="https://www.instagram.com/stariplac/">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 2.21097C15.204 2.21097 15.584 2.22323 16.85 2.28252C20.102 2.4338 21.621 4.01102 21.769 7.3106C21.827 8.60366 21.838 8.99208 21.838 12.2671C21.838 15.5432 21.826 15.9306 21.769 17.2237C21.62 20.5202 20.105 22.1005 16.85 22.2518C15.584 22.311 15.206 22.3233 12 22.3233C8.796 22.3233 8.416 22.311 7.151 22.2518C3.891 22.0994 2.38 20.5151 2.232 17.2226C2.174 15.9296 2.162 15.5422 2.162 12.2661C2.162 8.99106 2.175 8.60366 2.232 7.30958C2.381 4.01102 3.896 2.43278 7.151 2.2815C8.417 2.22323 8.796 2.21097 12 2.21097ZM12 0C8.741 0 8.333 0.0143105 7.053 0.0735967C2.695 0.278032 0.273 2.74965 0.073 7.20839C0.014 8.51779 0 8.93484 0 12.2661C0 15.5974 0.014 16.0155 0.072 17.3238C0.272 21.7785 2.69 24.2542 7.052 24.4586C8.333 24.5179 8.741 24.5322 12 24.5322C15.259 24.5322 15.668 24.5179 16.948 24.4586C21.302 24.2542 23.73 21.7826 23.927 17.3238C23.986 16.0155 24 15.5974 24 12.2661C24 8.93484 23.986 8.51779 23.928 7.20941C23.732 2.75885 21.311 0.279054 16.949 0.0746189C15.668 0.0143105 15.259 0 12 0V0ZM12 5.96746C8.597 5.96746 5.838 8.78765 5.838 12.2661C5.838 15.7446 8.597 18.5658 12 18.5658C15.403 18.5658 18.162 15.7456 18.162 12.2661C18.162 8.78765 15.403 5.96746 12 5.96746ZM12 16.3548C9.791 16.3548 8 14.5251 8 12.2661C8 10.0081 9.791 8.17741 12 8.17741C14.209 8.17741 16 10.0081 16 12.2661C16 14.5251 14.209 16.3548 12 16.3548ZM18.406 4.24714C17.61 4.24714 16.965 4.90645 16.965 5.71908C16.965 6.53171 17.61 7.19101 18.406 7.19101C19.201 7.19101 19.845 6.53171 19.845 5.71908C19.845 4.90645 19.201 4.24714 18.406 4.24714Z" />
              </svg>
            </a>
            <a href="https://www.facebook.com/stariplac">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
              </svg>
            </a>
            <a href="mailto:stariplac.info@gmail.com">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z" />
              </svg>
            </a>
          </div>
          <div className="hamburger" onClick={toogleMenu}>
            <svg
              className={
                isOpen ? "active ham hamRotate ham8" : "ham hamRotate ham8"
              }
              viewBox="0 0 100 100"
              width="80"
            >
              <path
                className="line top"
                d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
              />
              <path className="line middle" d="m 30,50 h 40" />
              <path
                className="line bottom"
                d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
              />
            </svg>
          </div>
        </nav>
        <div className="content__wrapper">
          <div className="left">
            <h1>
              Jede ti se nešto <span>slatko</span>?
            </h1>
            <p className="para">
              Dobrodošli u Stari plac! Najfinije palačinke od najkvalitetnijih
              sastojaka čekaju na vas! Naše ljubazno osoblje pomoći će vam
              odabrati najbolje kombinacije sastojaka, a naše kuharice vaše će
              iskustvo u Starom placu učiniti nezaboravnim! Stari plac je
              savršeno mjesto za obiteljski izlazak uz palačinke, kao i mjesto
              najslađih sastanaka i prijateljskih druženja.
            </p>
            <div className="button__wrapper">
              <a
                href="https://glovoapp.com/hr/hr/split/stari-plac/"
                className="order"
              >
                <p>Naruči</p>
                <img src={arrow} alt="Arrow" />
              </a>
              <img src={curvedArrow} alt="Arrow" />
              <p>Nemoj ostat gladan</p>
            </div>
          </div>
          <div className="right">
            <img src={main} alt="Main pancake" />
          </div>
        </div>
        <img
          className="background"
          src={background}
          alt="Background wallpaper"
        />
        <img className="blueberries" src={blueberries} alt="Blueberries" />
        <img className="blueberry" src={blueberrie} alt="Blueberry" />
        <img className="banana" src={banana} alt="Banana" />
      </header>
    </>
  );
};

export default Header;
