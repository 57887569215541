import { Suspense } from "react";
// Router
import { Route, Routes, useLocation } from "react-router-dom";

/* Imports of components */
import Loading from "./components/Loading";

/* Imports of pages */
import Home from "./pages/Home";
import Pancake from "./pages/Pancake";
import Test from "./pages/Test";

import "./scss/App.scss";

function App() {
  const location = useLocation();
  return (
    <Suspense fallback={<Loading />}>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/pancake/" element={<Pancake />} />
        <Route path="/test/" element={<Test />} />
      </Routes>
    </Suspense>
  );
}

export default App;
