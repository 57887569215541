import React from "react";

const Map = () => {
  return (
    <>
      <section className="map" id="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/d/embed?mid=1tqYq6YzjYTcXKL6crUTQfNGoDZfyN4w&hl=hr&ehbc=2E312F"
          width="100%"
          height="480"
        ></iframe>
      </section>
    </>
  );
};

export default Map;
