import { useState, useEffect } from "react";
/* Imports components */
import Header from "../components/Header";
import About from "../components/About";
import Menu from "../components/Menu";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Map from "../components/Map";

const Home = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="toTopBtn">
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      <main style={{ overflowX: "hidden" }}>
        <Header />
        <About />
        <Menu />
        <Map />
        <Contact />
        <Footer />
      </main>
    </>
  );
};

export default Home;
