import { Component } from "react";
import TinySlider from "tiny-slider-react";

import "tiny-slider/dist/tiny-slider.css";

const settings = {
  lazyload: true,
  nav: false,
  controls: false,
  mouseDrag: true,
  slideBy: "page",
};

class SliderMenu extends Component {
  onGoTo = (dir, e) => {
    this.ts.slider.goTo(dir);
    console.log(this.ts);
  };

  render() {
    console.log(this.props.pancake);
    return (
      <>
        {/* List data from state called pancake */}
        <TinySlider
          className="tiny-slider"
          settings={settings}
          ref={(ts) => (this.ts = ts)}
        >
          {/* {(this.props.pancake || []).map((pancake, index) => (
            <div key={index} className="test">
              <div className="card-content-slider">
                <img src={pancake.Slika} alt="slika" />
                <div className="content">
                  <h1>{pancake.Ime}</h1>
                  <p>{pancake.Sastojci}</p>
                  <h5>{pancake.Cijena} kn</h5>
                </div>
              </div>
            </div>
          ))} */}
          {/* List first 6 elements from pancake */}
          {(this.props.pancake?.slice(0, 6) || []).map((pancake, index) => {
            return (
              <div key={index} className="test">
                <div className="card-content-slider">
                  <img src={pancake.Slika} alt="slika" />
                  <div className="content">
                    <h1>{pancake.Ime}</h1>
                    <p>{pancake.Sastojci}</p>
                    <h5>{pancake.Cijena}</h5>
                  </div>
                </div>
              </div>
            );
          })}
        </TinySlider>
        <div className="buttons">
          <button
            type="button"
            onClick={(e) => this.onGoTo("prev", e)}
            className="prev-button"
          >
            <svg
              width="60"
              height="34"
              viewBox="0 0 87 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="87"
                y="19"
                width="86"
                height="4"
                rx="2"
                transform="rotate(-180 87 19)"
              />
              <rect
                x="19.6482"
                y="2.82837"
                width="22.97"
                height="4"
                rx="2"
                transform="rotate(135 19.6482 2.82837)"
              />
              <rect
                x="16.2449"
                y="33.4956"
                width="22.9737"
                height="4"
                rx="2"
                transform="rotate(-135 16.2449 33.4956)"
              />
            </svg>
          </button>
          <button
            type="button"
            onClick={(e) => this.onGoTo("next", e)}
            className="next-button"
          >
            <svg
              width="60"
              height="35"
              viewBox="0 0 87 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="15" width="86" height="4" rx="2" />
              <rect
                x="67"
                y="31.2422"
                width="22.97"
                height="4"
                rx="2"
                transform="rotate(-45 67 31.2422)"
              />
              <rect
                x="70.4033"
                y="0.574951"
                width="22.9737"
                height="4"
                rx="2"
                transform="rotate(45 70.4033 0.574951)"
              />
            </svg>
          </button>
        </div>
      </>
    );
  }
}

export default SliderMenu;
