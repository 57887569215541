import React from "react";

// Image imports
import leftFooter from "../assets/images/left-footer.jpg";
import rightFooter from "../assets/images/right-footer.jpg";
import footerLogo from "../assets/images/logo.png";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <img className="img-footer-1" src={leftFooter} alt="Left Footer" />
        <img className="img-footer-2" src={rightFooter} alt="Right Footer" />
        <div className="footer-wrapper">
          <a href="/">
            <img src={footerLogo} alt="Logo" />
          </a>
          <div className="content-footer">
            <h4>O nama</h4>
            <ul>
              <li>
                <a href="/">Cijena</a>{" "}
              </li>
              <li>
                <a href="/">Menu</a>{" "}
              </li>
              <li>
                <a href="/">Kontakt</a>
              </li>
            </ul>
          </div>
          <div className="content-footer">
            <h4>Radno vrijeme</h4>
            <ul>
              <li>Pon-Ned</li>
              <li>ST3: 7:00-23:00</li>
              <li>Stadion: 8:00-23:00</li>
            </ul>
          </div>
          <div className="content-footer">
            <h4>Developed by</h4>
            <ul>
              <li>
                <a
                  style={{ color: "#4fb9a5", fontWeight: "bold" }}
                  href="https://www.instagram.com/viktorbilokapic/"
                >
                  Viktor Bilokapić
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
