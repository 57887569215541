// Images imports
import coffee from "../assets/images/kafa.png";
import greenWave from "../assets/images/green-wave.svg";

const About = () => {
  return (
    <>
      <section className="about" id="about">
        <div className="about-wrapper">
          <div className="coffee-wrapper">
            <img src={coffee} alt="Coffee" />
          </div>
          <div className="about-content">
            <h3>O nama</h3>
            <h1>Palačinke</h1>
            <p>
              Stari Plac najpoznatija je palačinkarnica u gradu Splitu. Ime smo
              dobili po najljepšem stadionu na svijetu, splitskom Starom placu.
              Prvi objekt smo otvorili upravo tamo, preko puta Starog placa
              2014. godine i odmah napravili palačinke jednako slatke kao
              pobjede voljenog Hajduka. Ubrzo zatim, otvoren je Stari plac na
              Splitu 3. Već smo godinama omiljeno mjesto Splićanima koji
              obožavaju tradicionalni okus palačinki s punjenima koje obožavaju
              i djeca i odrasli. Posjetite nas!
            </p>
            <a href="/pancake">Menu</a>
          </div>
        </div>
        <img className={"greenWave"} src={greenWave} alt="Green Wave" />
      </section>
    </>
  );
};

export default About;
