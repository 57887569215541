import { useState, useEffect } from "react";
import axios from "axios";
import {
  API_URL,
  SALTY_PANCAKE_URL,
  FRIED_PANCAKE_URL,
  HOT_DRINKS_URL,
  ALCOHOLIC_DRINKS_URL,
  NON_ALCOHOLIC_DRINKS_URL,
} from "../config/api";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Pancake = () => {
  const [showButton, setShowButton] = useState(false);

  const [sweetPancake, setSweetPancake] = useState([]);
  const [saltyPancake, setSaltyPancake] = useState([]);
  const [friedPancake, setFriedPancake] = useState([]);
  const [hotDrinks, setHotDrinks] = useState([]);
  const [alcoholicDrinks, setAlcoholicDrinks] = useState([]);
  const [nonAlcoholicDrinks, setNonAlcoholicDrinks] = useState([]);

  const fetchData = () => {
    const sweetApi = `${API_URL}`;
    const saltyApi = `${SALTY_PANCAKE_URL}`;
    const friedApi = `${FRIED_PANCAKE_URL}`;
    const hotApi = `${HOT_DRINKS_URL}`;
    const alcoholicApi = `${ALCOHOLIC_DRINKS_URL}`;
    const nonAlcoholicApi = `${NON_ALCOHOLIC_DRINKS_URL}`;

    const getSweetPancake = axios.get(sweetApi);
    const getSaltyPancake = axios.get(saltyApi);
    const getFriedPancake = axios.get(friedApi);
    const getHotDrinks = axios.get(hotApi);
    const getAlcoholicDrinks = axios.get(alcoholicApi);
    const getNonAlcoholicDrinks = axios.get(nonAlcoholicApi);
    axios
      .all([
        getSweetPancake,
        getSaltyPancake,
        getFriedPancake,
        getHotDrinks,
        getAlcoholicDrinks,
        getNonAlcoholicDrinks,
      ])
      .then(
        axios.spread((...allData) => {
          const allDataSweet = allData[0].data;
          const allDataSalty = allData[1].data;
          const allDataFried = allData[2].data;
          const allDataHot = allData[3].data;
          const allDataAlcoholic = allData[4].data;
          const allDataNonAlcoholic = allData[5].data;

          setSweetPancake(allDataSweet);
          setSaltyPancake(allDataSalty);
          setFriedPancake(allDataFried);
          setHotDrinks(allDataHot);
          setAlcoholicDrinks(allDataAlcoholic);
          setNonAlcoholicDrinks(allDataNonAlcoholic);
        })
      );
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <>
      {showButton && (
        <button onClick={scrollToTop} className="toTopBtn">
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
      <Navbar />
      <section className="bigMenu">
        <div className="selection">
          <ul className="selected-apis">
            <li>
              <a href="#slatke">Slatke Palačinke</a>
            </li>
            <li>
              <a href="#slane">Slane Palačinke</a>
            </li>
            <li>
              <a href="#pohane">Pohane Palačinke</a>
            </li>
            <li>
              <a href="#topli">Topli Napitci</a>
            </li>
            <li>
              <a href="#bezalk">Bezalkoholna pića</a>
            </li>
            <li>
              <a href="#alk">Alkoholna pića</a>
            </li>
          </ul>
        </div>
        <h1 className="main-title" id="slatke">
          Slatke palačinke
        </h1>
        <div className="card-menu-wrapper">
          {sweetPancake?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Sastojci}</p>
              </div>
            );
          })}
        </div>
        <h1 className="main-title" id="slane">
          Slane palačinke
        </h1>
        <div className="card-menu-wrapper">
          {saltyPancake?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Sastojci}</p>
              </div>
            );
          })}
        </div>
        <h1 className="main-title" id="pohane">
          Pohane palačinke
        </h1>
        <div className="card-menu-wrapper">
          {friedPancake?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Sastojci}</p>
              </div>
            );
          })}
        </div>
        <h1 className="main-title" id="topli">
          Topli Napitci
        </h1>
        <div className="card-menu-wrapper">
          {hotDrinks?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Kolicina}</p>
              </div>
            );
          })}
        </div>
        <h1 className="main-title" id="bezalk">
          Bezalkoholna pića
        </h1>
        <div className="card-menu-wrapper">
          {nonAlcoholicDrinks?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Kolicina}</p>
              </div>
            );
          })}
        </div>
        <h1 className="main-title" id="alk">
          Alkoholna pića
        </h1>
        <div className="card-menu-wrapper">
          {alcoholicDrinks?.map((pancake: any, index: any) => {
            return (
              <div key={index} className="price-wrapper">
                <div className="price-tags">
                  <h1>{pancake.Ime}</h1>
                  <h4>{pancake.Cijena}</h4>
                </div>
                <p>{pancake.Kolicina}</p>
              </div>
            );
          })}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Pancake;
