import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav>
      <div className="title">
        <h1>Cjenik</h1>
      </div>
      <ul className="cjenik-items">
        <li>
          <a className="pancake-links" href="/">
            Naslovna
          </a>
        </li>
        <li>
          <a className="pancake-links active" href="/pancake">
            Jelovnik
          </a>
        </li>
        <li>
          <a className="pancake-links" href="/#about">
            O nama
          </a>
        </li>
        <li>
          <a className="find-btn" href="/#map">
            Pronađi nas
          </a>
        </li>
      </ul>
      <div className="overlay" style={{ width: isOpen ? "100%" : "0%" }}>
        <ul>
          <li>
            <a href="/">Naslovna</a>
          </li>
          <li>
            <a href="/pancake">Jelovnik</a>
          </li>
          <li>
            <a href="/#about">O nama</a>
          </li>
          <li>
            <a className="find-btn-mobile" href="/#map">
              Pronađi nas
            </a>
          </li>
        </ul>
      </div>
      <div className="hamburger" onClick={toogleMenu}>
        <svg
          className={
            isOpen ? "active ham hamRotate ham8" : "ham hamRotate ham8"
          }
          viewBox="0 0 100 100"
          width="80"
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
          />
          <path className="line middle" d="m 30,50 h 40" />
          <path
            className="line bottom"
            d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
          />
        </svg>
      </div>
    </nav>
  );
};

export default Navbar;
